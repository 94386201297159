// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyBxkAcuT07Cwe3DLVZg8z-j7ea0OHPk8A4",
    authDomain: "ela-live.firebaseapp.com",
    projectId: "ela-live",
    storageBucket: "ela-live.appspot.com",
    messagingSenderId: "653503746999",
    appId: "1:653503746999:web:f62010224bccae89330e32",
    measurementId: "G-4NT7BDMYVZ",
    vapidKey: "BE5HXoNIsy222-FoaRrPO0ANnYv6T5AYCxkeo0RYbpnyznnDlKCs05CPflbg73AKSt0oLc-8XM5scN6acVVGAOw"
},
};


export const baseUrl = 'https://apiela.elaapp.com/api/'; //  publish api 



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
